/**
 * Project-wide constants for the api routes of the app.
 *
 * So we can replace them easier in the future.
 */
export enum ApiRoute {
  /**
   * Authentication callback URL.
   * HTTP GET
   */
  CALLBACk = "/api/auth/callback",
  /**
   * Apollo GraphQL Endpoint.
   * HTTP POST
   */
  GRAPHQL = "/api/graphql",
  /**
   * Authentication login URL.
   * HTTP GET
   */
  LOGIN = "/api/auth/login",
  /**
   * Authentication logout URL.
   * HTTP GET
   */
  LOGOUT = "/api/auth/logout",
  /**
   * Authentication me URL.
   * HTTP GET
   */
  ME = "/api/auth/me",
  /**
   * API path to enqueue a call.
   * This will put the client on hold until a user is available.
   */
  TWILIO_ROUTER = "/api/twilio/voice/router",
  /**
   * API path to request a Twilio Device token.
   * HTTP POST
   */
  TWILIO_TOKEN = "/api/twilio/token",
}

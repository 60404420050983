import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
};

/** Gender. */
export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a user in the database. This requires the "user:create" Auth0 permission. */
  userCreate: User;
  /** Updates a user in the database. Returns the updated user. This requires the "user:update" Auth0 permission. */
  userUpdate: User;
  /** Updates the user's availability status. */
  userUpdateAvailability: User;
  /** Deletes a user in the database. Returns the deleted user. This requires the "user:delete" Auth0 permission. */
  usersDelete: Array<User>;
};


export type MutationUserCreateArgs = {
  input: UserCreateInput;
};


export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
};


export type MutationUserUpdateAvailabilityArgs = {
  input: UserUpdateAvailabilityInput;
};


export type MutationUsersDeleteArgs = {
  input: UsersDeleteInput;
};

export type Query = {
  __typename?: 'Query';
  /** Retrieves all the users in the database. Returns an empty array when there is no user in the system. This requires the "user:read" Auth0 permission. */
  users: Array<User>;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UsersFilterInput>;
};

/** User salutations. */
export enum Salutation {
  Mr = 'MR',
  Mrs = 'MRS',
  Ms = 'MS',
  PreferNotToSay = 'PREFER_NOT_TO_SAY'
}

export type Subscription = {
  __typename?: 'Subscription';
  /** Subscribes to the user availability updates. */
  userAvailabilityStatusUpdate: Array<User>;
  /** SAMPLE SUBSCRIPTION: Subscribe to user reads. */
  userRead: Array<User>;
};

/** User record used to manage user access and permission. */
export type User = {
  __typename?: 'User';
  /** User's availability details. */
  availability: UserAvailability;
  /** Email address of the user. */
  email: Scalars['String']['output'];
  /** Unique identifier of the user. */
  id: Scalars['String']['output'];
  /** Personal details of the user. */
  profile: UserProfile;
  /** Third-party ID of the user. e.g.: Auth0 User ID. */
  thirdPartyId: Scalars['String']['output'];
  /** Type of user. */
  type: UserType;
};

export type UserAvailability = {
  __typename?: 'UserAvailability';
  /** User availability status. */
  status: UserAvailabilityStatus;
  /** Date and time the availability status was set. */
  updatedDate: Scalars['DateTimeISO']['output'];
};

/** User availability status. */
export enum UserAvailabilityStatus {
  Available = 'AVAILABLE',
  Break = 'BREAK',
  Busy = 'BUSY',
  Offline = 'OFFLINE',
  OnACall = 'ON_A_CALL'
}

/** User availability input for creating a user. */
export type UserCreateAvailabilityInput = {
  /** Availability status of the user. */
  status: UserAvailabilityStatus;
};

/** Input for creating a user. */
export type UserCreateInput = {
  /** Availability of the user. */
  availability?: InputMaybe<UserCreateAvailabilityInput>;
  /** Email of the user. This will also serve as the user's username. */
  email: Scalars['String']['input'];
  /** Profile of the user. */
  profile: UserCreateProfileInput;
  /** Third--party ID of the user. This could bhe the ID of the user from the identity provider. */
  thirdPartyId: Scalars['String']['input'];
  /** Type of the user. */
  type: UserType;
};

/** Profile input for creating a user. */
export type UserCreateProfileInput = {
  /** Full name of the user. */
  fullName: Scalars['String']['input'];
  /** Gender of the user. */
  gender: Gender;
  /** Initials of the user. */
  initials?: InputMaybe<Scalars['String']['input']>;
  /** Nickname of the user. */
  nickname?: InputMaybe<Scalars['String']['input']>;
  /** Salutation of the user. */
  salutation?: InputMaybe<Salutation>;
};

/** Personal information of the user. */
export type UserProfile = {
  __typename?: 'UserProfile';
  /** Full name of the user. */
  fullName: Scalars['String']['output'];
  /** Gender of the user. */
  gender?: Maybe<Gender>;
  /** Initials of the user. This will be auto generated. */
  initials?: Maybe<Scalars['String']['output']>;
  /** Nickname of the user. */
  nickname?: Maybe<Scalars['String']['output']>;
  /** Salutation of the user. */
  salutation?: Maybe<Salutation>;
};

/** Types of  a user. */
export enum UserType {
  Default = 'DEFAULT',
  Owner = 'OWNER'
}

/** Update user availability input. */
export type UserUpdateAvailabilityInput = {
  /** User availability status. */
  availabilityStatus: UserAvailabilityStatus;
};

/** Input for updating a user. */
export type UserUpdateInput = {
  /** Email of the user. This will also serve as the user's username. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** ID of the user. */
  id: Scalars['String']['input'];
  /** Profile of the user. */
  profile: UserUpdateProfileInput;
  /** Third--party ID of the user. This could bhe the ID of the user from the identity provider. */
  thirdPartyId: Scalars['String']['input'];
  /** Type of the user. */
  type?: InputMaybe<UserType>;
};

/** Profile input for updating a user. */
export type UserUpdateProfileInput = {
  /** Full name of the user. */
  fullName: Scalars['String']['input'];
  /** Gender of the user. */
  gender: Gender;
  /** Initials of the user. */
  initials?: InputMaybe<Scalars['String']['input']>;
  /** Nickname of the user. */
  nickname?: InputMaybe<Scalars['String']['input']>;
  /** Salutation of the user. */
  salutation?: InputMaybe<Salutation>;
};

/** Input for deleting one or more users. */
export type UsersDeleteInput = {
  /** IDs of the user. */
  ids: Array<Scalars['String']['input']>;
};

/** Filters for retrieving users. */
export type UsersFilterInput = {
  /** Email address of the user. */
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Arrays of user IDs of the user. */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Full or partial name of the user. This currently requires the exact names. */
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserUpdateAvailabilityMutationVariables = Exact<{
  input: UserUpdateAvailabilityInput;
}>;


export type UserUpdateAvailabilityMutation = { __typename?: 'Mutation', userUpdateAvailability: { __typename?: 'User', availability: { __typename?: 'UserAvailability', status: UserAvailabilityStatus, updatedDate: any } } };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', email: string, id: string, availability: { __typename?: 'UserAvailability', status: UserAvailabilityStatus, updatedDate: any }, profile: { __typename?: 'UserProfile', fullName: string } }> };

export type UserAvailabilityStatusUpdateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserAvailabilityStatusUpdateSubscription = { __typename?: 'Subscription', userAvailabilityStatusUpdate: Array<{ __typename?: 'User', id: string, email: string, thirdPartyId: string, type: UserType, availability: { __typename?: 'UserAvailability', status: UserAvailabilityStatus, updatedDate: any }, profile: { __typename?: 'UserProfile', fullName: string, gender?: Gender | null, initials?: string | null, nickname?: string | null, salutation?: Salutation | null } }> };


export const UserUpdateAvailabilityDocument = gql`
    mutation UserUpdateAvailability($input: UserUpdateAvailabilityInput!) {
  userUpdateAvailability(input: $input) {
    availability {
      status
      updatedDate
    }
  }
}
    `;
export type UserUpdateAvailabilityMutationFn = Apollo.MutationFunction<UserUpdateAvailabilityMutation, UserUpdateAvailabilityMutationVariables>;

/**
 * __useUserUpdateAvailabilityMutation__
 *
 * To run a mutation, you first call `useUserUpdateAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateAvailabilityMutation, { data, loading, error }] = useUserUpdateAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateAvailabilityMutation, UserUpdateAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateAvailabilityMutation, UserUpdateAvailabilityMutationVariables>(UserUpdateAvailabilityDocument, options);
      }
export type UserUpdateAvailabilityMutationHookResult = ReturnType<typeof useUserUpdateAvailabilityMutation>;
export type UserUpdateAvailabilityMutationResult = Apollo.MutationResult<UserUpdateAvailabilityMutation>;
export type UserUpdateAvailabilityMutationOptions = Apollo.BaseMutationOptions<UserUpdateAvailabilityMutation, UserUpdateAvailabilityMutationVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    availability {
      status
      updatedDate
    }
    email
    id
    profile {
      fullName
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export function useUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserAvailabilityStatusUpdateDocument = gql`
    subscription UserAvailabilityStatusUpdate {
  userAvailabilityStatusUpdate {
    availability {
      status
      updatedDate
    }
    id
    email
    profile {
      fullName
      gender
      initials
      nickname
      salutation
    }
    thirdPartyId
    type
  }
}
    `;

/**
 * __useUserAvailabilityStatusUpdateSubscription__
 *
 * To run a query within a React component, call `useUserAvailabilityStatusUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserAvailabilityStatusUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAvailabilityStatusUpdateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUserAvailabilityStatusUpdateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UserAvailabilityStatusUpdateSubscription, UserAvailabilityStatusUpdateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UserAvailabilityStatusUpdateSubscription, UserAvailabilityStatusUpdateSubscriptionVariables>(UserAvailabilityStatusUpdateDocument, options);
      }
export type UserAvailabilityStatusUpdateSubscriptionHookResult = ReturnType<typeof useUserAvailabilityStatusUpdateSubscription>;
export type UserAvailabilityStatusUpdateSubscriptionResult = Apollo.SubscriptionResult<UserAvailabilityStatusUpdateSubscription>;
"use client";

/**
 * Third-party libraries.
 */
import { Call, Device } from "@twilio/voice-sdk";
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

/**
 * Project components.
 */
import { useApplicationContext } from "@/components/client/context";
import { UserAvailabilityStatus } from "@/components/client/graphql";
import { TwilioCallEvent, useTwilioDevice } from "@/components/client/twilio";
import { notification } from "antd";
import { useAuthenticationContext } from "../authentication";

export type CallWithCustomProperties = Call & {
  /**
   * Date the call was initiated.
   */
  date?: Date;
  /**
   * Indicates whether the call is muted.
   */
  muted?: boolean;
};

export type SelectedCommunicationLog = {
  // /**
  //  * ID of the communication log.
  //  */
  // id: CommunicationLogCardProps["id"];
  /**
   * Twilio call instance.
   */
  call?: CallWithCustomProperties;
  /**
   * Indicates whether the call is muted.
   */
  muted: boolean;
};

/**
 * Twilio context.
 */
type TwilioContext = {
  /**
   * Calls a phone number.
   *
   * @returns A Twilio call instance.
   */
  call: (args: {
    /**
     * Phone number to be called.
     */
    phoneNumber: string;
  }) => Promise<void>;
  /**
   * Active twilio call.
   */
  calls: Call[];
  /**
   * Twilio device.
   */
  device: Device | null;
  /**
   * Twilio device error.
   */
  deviceError: unknown;
  /**
   * Twilio device is loading.
   */
  deviceInitializing: boolean;
  /**
   * Twilio device is registered.
   */
  deviceRegistered: boolean;
  /**
   * Twilio device is registering.
   */
  registering: boolean;
  /**
   * Register the Device instance with Twilio, allowing it to receive incoming calls.
   *
   * This will open a signaling WebSocket, so the browser tab may show the 'recording' icon.
   *
   * It's not necessary to call device.register() in order to make outgoing calls.
   */
  registerDevice: () => Promise<void>;
  /**
   * Selected communication log.
   */
  selectedCommunicationLog: SelectedCommunicationLog | null;
  /**
   * Set the selected communication log.
   */
  setSelectedCommunicationLog: Dispatch<
    SetStateAction<SelectedCommunicationLog | null>
  >;
  /**
   * Mute the selected call.
   */
  toggleMute: () => void;
  /**
   * Unregister the Device instance with Twilio. This will prevent the Device
   * instance from receiving incoming calls.
   */
  unregisterDevice: () => Promise<void>;
};

/**
 * Twilio related context.
 */
const TwilioContext = React.createContext<TwilioContext>({
  call: () => Promise.resolve(),
  calls: [],
  device: null,
  deviceError: null,
  deviceInitializing: true,
  deviceRegistered: false,
  registering: false,
  registerDevice: () => Promise.resolve(),
  selectedCommunicationLog: null,
  setSelectedCommunicationLog: () => {},
  toggleMute: () => {},
  unregisterDevice: () => {
    return Promise.resolve();
  },
});

/**
 * Use Twilio context.
 */
export const useTwilioContext = () => {
  return React.useContext(TwilioContext);
};

/**
 * Twilio context provider.
 */
export const TwilioContextProvider = ({ children }: PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================
  /**
   * The active Twilio call.
   * The purpose of this variable is to store one or more active calls of an
   * agent. This is so we can easily access them if we need to interact with them.
   *
   * @see https://www.twilio.com/docs/voice/sdks/javascript/twiliocall
   */
  const [calls, setCalls] = useState<CallWithCustomProperties[]>([]);

  /**
   * Indicates which communication log is currently active on the screen.
   * This could control which subpanels are shown.
   */
  const [selectedCommunicationLog, setSelectedCommunicationLog] =
    useState<SelectedCommunicationLog | null>(null);

  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  /**
   * User availability status.
   */
  const {
    defaultUserAvailabilityStatus,
    userAvailabilityStatus,
    setUserAvailabilityStatus,
    updatingUserAvailabilityStatus,
  } = useApplicationContext();

  const { user } = useAuthenticationContext();

  const {
    destroyDevice,
    device: device,
    error: deviceError,
    initialize: initializeDevice,
    initializing: deviceInitializing,
    registered: deviceRegistered,
    registering,
    registerDevice,
    unregisterDevice,
  } = useTwilioDevice({
    callback: {
      destroyed: () => {
        console.log("Twilio device destroyed.");

        setUserAvailabilityStatus({
          force: true,
          status: UserAvailabilityStatus.Offline,
        });
      },
      error: (error) => {
        console.error("Twilio device error:", error);
      },
      incoming: ({ call }) => {
        console.log("Incoming call:", call);

        /**
         * Add the call to the list of connected calls.
         */
        addCall({ call });
      },
      registered: () => {
        console.log("Twilio device registered.");
      },
      registering: () => {
        console.log("Twilio device registering.");
      },
      token_will_expire: () => {
        console.log("Twilio device token will expire.");
      },
      unregistered: () => {
        console.log("Twilio device unregistered.");
      },
    },
  });

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  /**
   * Remove the call from the list of connected calls.
   */
  function removeCall({
    call,
  }: {
    /**
     * Twilio call instance.
     */
    call: Call;
  }) {
    setCalls(
      (previousCalls) =>
        previousCalls.filter(
          (previousCall) =>
            previousCall.parameters.CallSid !== call.parameters.CallSid
        ) ?? []
    );
  }

  /**
   * Attach event listeners to the call.
   */
  const setCallEventListeners = useCallback(
    ({
      call,
    }: {
      /**
       * Twilio call instance.
       */
      call: Call;
    }) => {
      const onCallAccept = (call: Call) => {
        console.log("Call accepted. Call SID: ", call.parameters.CallSid);
        setSelectedCommunicationLog({
          call: call,
          muted: call.isMuted(),
        });
      };

      const onCallCancel = () => {
        console.log("Call cancelled.");
        removeCall({ call });
        setSelectedCommunicationLog(null);
      };

      const onCallDisconnect = () => {
        console.log("Call disconnected.");
        removeCall({ call });
        setSelectedCommunicationLog(null);
      };

      const onCallMute = (
        /**
         * Indicates whether the call is muted.
         */
        muted: boolean,
        /**
         * Twilio call instance.
         */
        call: Call
      ) => {
        console.log("Call muted:", muted);

        if (
          selectedCommunicationLog?.call?.parameters.CallSid !==
          call.parameters.CallSid
        ) {
          return;
        }

        // Update the selected communication log mute state.
        setSelectedCommunicationLog((previousSelectedCommunicationLog) => {
          if (!previousSelectedCommunicationLog?.call) {
            return previousSelectedCommunicationLog;
          }

          const newSelectedCommunicationLog = {
            ...previousSelectedCommunicationLog,
          };

          newSelectedCommunicationLog.muted = muted;

          return newSelectedCommunicationLog;
        });
      };

      const onCallReject = () => {
        console.log("Call rejected.");
        removeCall({ call });
      };

      const onCallRing = (call: Call) => {
        console.log("Call ringing.");
      };

      call.on(TwilioCallEvent.ACCEPT, onCallAccept);
      call.on(TwilioCallEvent.CANCEL, onCallCancel);
      call.on(TwilioCallEvent.DISCONNECT, onCallDisconnect);
      call.on(TwilioCallEvent.MUTE, onCallMute);
      call.on(TwilioCallEvent.REJECT, onCallReject);
      call.on(TwilioCallEvent.RINGING, onCallRing);
    },
    [selectedCommunicationLog]
  );
  /**
   * Add the call to the list of connected calls.
   */
  const addCall = useCallback(
    ({
      call,
    }: {
      /**
       * Twilio call instance.
       */
      call: Call;
    }) => {
      const customCall = call as CallWithCustomProperties;

      // Append the date when the call was initiated to the call object.
      customCall.date = new Date();
      // Track if the call is muted.
      customCall.muted = call.isMuted();

      setCallEventListeners({ call: customCall });

      setCalls((previousCalls) => [...previousCalls, call]);
    },
    [setCallEventListeners]
  );

  /**
   * Calls a phone number.
   */
  const call = useCallback(
    async ({ phoneNumber }: { phoneNumber: string }) => {
      const call = await device?.connect({
        params: {
          To: phoneNumber,
        },
      });

      if (!call) {
        throw new Error("Failed to call phone number.");
      }

      addCall({ call });
    },
    [addCall, device]
  );

  /**
   * Mutes a selected call.
   */
  const toggleMute = useCallback(() => {
    if (!selectedCommunicationLog?.call) {
      return;
    }

    // Mute the call.
    selectedCommunicationLog.call.mute(!selectedCommunicationLog.muted);

    // Store the call state.
    setSelectedCommunicationLog({
      call: selectedCommunicationLog.call,
      muted: !selectedCommunicationLog.muted,
    });
  }, [selectedCommunicationLog]);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Initialize Twilio device if it hasn't been intiialized yet.
   */
  useEffect(() => {
    /**
     * Only authenticated users are allowed to use Twilio device.
     *
     * Make sure that only one device is initialized.
     */
    if (!user || device || deviceInitializing) {
      return;
    }

    initializeDevice();

    return () => {
      destroyDevice();
    };
  }, [destroyDevice, device, deviceInitializing, initializeDevice, user]);

  /**
   * Register or unregister Twilio device based on user availability status.
   */
  useEffect(() => {
    // Prevent any succeeding code from executing if device is not yet initialized.
    if (!device || deviceInitializing || registering) {
      return;
    }

    // Register device if user is available and device is not registered.
    if (
      userAvailabilityStatus !== UserAvailabilityStatus.Offline &&
      !deviceRegistered
    ) {
      registerDevice();
    }
    // Unregister device if user is on a break, on a call or offline and device is registered.
    else if (
      userAvailabilityStatus === UserAvailabilityStatus.Offline &&
      deviceRegistered
    ) {
      unregisterDevice();
    }
  }, [
    device,
    deviceInitializing,
    deviceRegistered,
    registerDevice,
    registering,
    unregisterDevice,
    userAvailabilityStatus,
  ]);

  /**
   * Display a notification when there's a Twilio device error.
   */
  useEffect(() => {
    // if (updatingUserAvailabilityStatus) {
    //   return;
    // }

    // Set the user to offline if there's any Twilio device error.
    if (
      deviceError
      // &&
      // userAvailabilityStatus !== UserAvailabilityStatus.Offline
    ) {
      notification.error({
        message: "Twilio device error.",
        description: "An error occurred while updating Twilio device.",
        showProgress: true,
        pauseOnHover: true,
        key: "twilio-device-error",
      });

      // setUserAvailabilityStatus({ status: UserAvailabilityStatus.Offline });
    }
  }, [
    deviceError,
    // setUserAvailabilityStatus,
    // updatingUserAvailabilityStatus,
    // userAvailabilityStatus,
  ]);

  /**
   * Update the user availability status based on the number of calls.
   */
  useEffect(() => {
    setUserAvailabilityStatus({
      status: calls?.length
        ? UserAvailabilityStatus.OnACall
        : defaultUserAvailabilityStatus,
    });
  }, [
    calls,
    defaultUserAvailabilityStatus,
    setUserAvailabilityStatus,
    userAvailabilityStatus,
  ]);

  return (
    <TwilioContext.Provider
      value={{
        call,
        calls,
        device,
        deviceError,
        deviceInitializing,
        deviceRegistered,
        registerDevice,
        registering,
        selectedCommunicationLog,
        setSelectedCommunicationLog,
        toggleMute,
        unregisterDevice,
      }}
    >
      {children}
    </TwilioContext.Provider>
  );
};

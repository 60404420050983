"use client";

/**
 * Third-party libraries.
 */
import { notification as antdNotification } from "antd";
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useState
} from "react";

import {
  UserAvailabilityStatus,
  useUserAvailabilityStatusUpdateSubscription,
  useUserUpdateAvailabilityMutation,
} from "@/components/client/graphql";
import { NotificationInstance } from "antd/es/notification/interface";
import { useAuthenticationContext } from "../authentication";

type SetUserAvailabilityStatusArgs = {
  /**
   * Indicates that the status should be updated regardless of the current status.
   */
  force?: boolean;
  /**
   * Indicates that the status should be the default status.
   */
  isDefault?: boolean;
  /**
   * The user availability status.
   */
  status: UserAvailabilityStatus;
};

/**
 * Application context.
 */

export type ApplicationContext = {
  /**
   * The default user availability status.
   * This is manually selected by the user.
   */
  defaultUserAvailabilityStatus: UserAvailabilityStatus;
  /**
   * Indicates if the application is in mock data mode.
   */
  mockData: boolean;
  /**
   * Ant Design notification instance.
   *
   * Use this to show notifications.
   */
  notification: NotificationInstance;
  /**
   * Sets the application to use mock data.
   */
  setMockData: Dispatch<SetStateAction<boolean>>;
  /**
   * Shows or hides the dialer.
   */
  setShowDialer: Dispatch<SetStateAction<boolean>>;
  /**
   * User availability status setter.
   */
  setUserAvailabilityStatus: (args: SetUserAvailabilityStatusArgs) => void;
  /**
   * Indicates if the dialer is shown or hidden.
   *
   * @default false
   */
  showDialer: boolean;
  /**
   * Updating user availability status.
   */
  updatingUserAvailabilityStatus: boolean;
  /**
   * The availability status of the user.
   *
   * @default offline.
   */
  userAvailabilityStatus: UserAvailabilityStatus;
};

/**
 * Application context.
 */
const ApplicationContext = React.createContext<ApplicationContext>({
  defaultUserAvailabilityStatus: UserAvailabilityStatus.Offline,
  mockData: false,
  notification: {
    success: () => {},
    error: () => {},
    info: () => {},
    warning: () => {},
    open: () => {},
    destroy: () => {},
  },
  setMockData: () => {},
  setShowDialer: () => {},
  setUserAvailabilityStatus: () => {},
  showDialer: false,
  updatingUserAvailabilityStatus: false,
  userAvailabilityStatus: UserAvailabilityStatus.Offline,
});

/**
 * Use Application Context hook.
 */
export const useApplicationContext = () => {
  return React.useContext(ApplicationContext);
};

/**
 * Application context provider.
 */
export const ApplicationContextProvider = ({ children }: PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The user availability status that the user manually selected.
   */
  const [defaultUserAvailabilityStatus, setDefaultUserAvailabilityStatus] =
    useState<UserAvailabilityStatus>(UserAvailabilityStatus.Offline);

  /**
   * Indicates wheter the application should use mock data.
   */
  const [mockData, setMockData] = useState<boolean>(false);

  /**
   * Shows or hides the dialer.
   */
  const [showDialer, setShowDialer] = useState<boolean>(false);

  /**
   * The user availability status.
   */
  const [userAvailabilityStatus, _setUserAvailabilityStatus] =
    useState<UserAvailabilityStatus>(UserAvailabilityStatus.Offline);

  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { user } = useAuthenticationContext();

  const [
    updateUserAvailabilityStatus,
    { loading: updatingUserAvailabilityStatus },
  ] = useUserUpdateAvailabilityMutation();

  /**
   * Ant Design notification.
   */
  const [notification, contextHolder] = antdNotification.useNotification({
    top: 64,
    stack: {
      threshold: 5,
    },
  });

  useUserAvailabilityStatusUpdateSubscription();

  // ===========================================================================
  // ===========================================================================
  // Function
  // ===========================================================================
  // ===========================================================================

  /**
   * Updates the user availability status on the server first before updating the
   * state. This is to ensure that the status is synced to the server first
   * before reflecting changes.
   */
  const setUserAvailabilityStatus = useCallback(
    ({ force, isDefault, status }: SetUserAvailabilityStatusArgs) => {
      const errorMessage = `Error updating user availability status to ${status}. Please try again.`;

      /**
       * Do not allow the user to update the status if the user is not logged in.
       */
      if (!user) {
        console.warn("User is not logged in.");
        return;
      }

      /**
       * Do not allow the user to update the status if the status is currently
       * being updated. This is to prevent multiple updates at the same time.
       */
      if (updatingUserAvailabilityStatus) {
        console.warn("User availability status is currently being updated.");
        return;
      }

      /**
       * Do not allow the user to update the status if the status is already the
       * same as the current status. This is to prevent unnecessary updates.
       *
       * This check would be ignored if the "force" flag is set to true.
       */
      if (status === userAvailabilityStatus && !force) {
        console.warn(
          `User availability status is already ${status}. No need to update.`
        );
        return;
      }

      updateUserAvailabilityStatus({
        variables: {
          input: {
            availabilityStatus: status,
          },
        },
        onCompleted: async function (data, clientOptions) {
          if (status !== data.userUpdateAvailability.availability.status) {
            console.error(errorMessage);

            notification.error({
              message: "Error updating user availability status.",
              description: errorMessage,
              showProgress: true,
              pauseOnHover: true,
            });

            return;
          }

          _setUserAvailabilityStatus(
            data.userUpdateAvailability.availability.status
          );

          if (isDefault) {
            // Keep track of the user selected availability status.
            setDefaultUserAvailabilityStatus(
              data.userUpdateAvailability.availability.status
            );
          }

          const message = `User availability status updated to ${data.userUpdateAvailability.availability.status}.`;

          console.log("User availability status updated:", data);
        },
        onError(error, clientOptions) {
          const errorMessage = `Error updating user availability status to ${status}. Please try again.`;

          console.error(errorMessage, error);

          notification.error({
            message: "Error updating user availability status.",
            description: errorMessage,
            showProgress: true,
            pauseOnHover: true,
          });
        },
      });
    },
    [
      notification,
      updateUserAvailabilityStatus,
      updatingUserAvailabilityStatus,
      user,
      userAvailabilityStatus,
    ]
  );

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  // /**
  //  * Set the user to offline before the user reloads/leaves the page.
  //  */
  // useEffect(() => {
  //   const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  //     // event.preventDefault();

  //     console.log("User is leaving the page.", {
  //       target: event.target,
  //       currentTarget: event.currentTarget,
  //       type: event.type,
  //       at_target: event.AT_TARGET,
  //       currentUrl: window.location.href,
  //       referrerUrl: document.referrer,
  //     });

  //     if (!user) {
  //       return;
  //     }

  //     setUserAvailabilityStatus({
  //       force: true,
  //       status: UserAvailabilityStatus.Offline,
  //     });
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [setUserAvailabilityStatus, user]);

  return (
    <ApplicationContext.Provider
      value={{
        defaultUserAvailabilityStatus,
        mockData,
        notification,
        setMockData,
        setShowDialer,
        setUserAvailabilityStatus,
        showDialer,
        updatingUserAvailabilityStatus,
        userAvailabilityStatus,
      }}
    >
      {contextHolder}
      {children}
    </ApplicationContext.Provider>
  );
};
